<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item label="电梯属性类别" prop="propertytype">
        <a-select v-model="formDatas.propertytype" :disabled="showType=='detail' || !!propertytype">
          <a-select-option v-for="(item, index) in propTypes" :key="index" :value="item.value">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="属性值" prop="value">
        <a-input v-model.trim="formDatas.value" :disabled="showType=='detail'" />
      </a-form-model-item>
      <template v-if="formDatas.propertytype == 3">
        <a-form-model-item label="联系人" prop="contactperson">
          <a-input v-model.trim="formDatas.contactperson" :disabled="showType=='detail'" />
        </a-form-model-item>
        <a-form-model-item label="联系地址" prop="contactaddress">
          <a-input v-model.trim="formDatas.contactaddress" :disabled="showType=='detail'" />
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="contactphone">
          <a-input v-model.trim="formDatas.contactphone" :max-length="11" :disabled="showType=='detail'" />
        </a-form-model-item>
      </template>
      <a-form-model-item label="描述" prop="propertydesc">
        <a-input v-model.trim="formDatas.propertydesc" :disabled="showType=='detail'" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import propTypes from '@/json/propTypes'
import {addProperty, modifyProperty} from "A/jcgn";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    propertytype: {
      default: ''
    }
  },
  data() {
    return {
      propTypes,
      modalVisible: false,
      formDatas: {
        propertyid: '',
        propertytype: '',
        value: '',
        contactperson: '',
        contactaddress: '',
        contactphone: '',
        propertydesc: '',
      },
      formRules: {
        propertytype: [{required: true, message: '请选择电梯属性类别'}],
        value: [{required: true, message: '请输入属性值'}],
        contactphone: [
          {
            validator: (rule, value, callback) => {
              if(value==='' || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    if(this.propertytype) {
      this.formDatas.propertytype = this.propertytype;
    }
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            this.formDatas[key] = this.detailData[key]||'';
          }
        })
      }
    },
    resetDatas() {
      this.formDatas.propertyid = '';
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas
            };
            if(this.showType == 'add') {
              addProperty(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyProperty(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    }
  }
}
</script>